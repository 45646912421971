<template>
  <div class="mr-1">
    <ImportExcelButton :onSuccess="loadDataInTable" />
  </div>
</template>

<script>
import ImportExcelButton from "@/Core/Views/CommonPlugins/import-excel/ImportExcelButton";

export default {
  components: {
    ImportExcelButton
  },
  methods: {
    loadDataInTable({ results }) {
      // results.forEach(element => {
      //   console.log(element);
      // element.startDate = new Date(element.startDate)
      //   .toISOString()
      //   .replace("T", " ")
      //   .replace("Z", "")
      //   .slice(0, 10);

      // element.endDate = new Date(element.endDate)
      //   .toISOString()
      //   .replace("T", " ")
      //   .replace("Z", "")
      //   .slice(0, 10);
      // });
      this.importData(results);
    },

    async importData(results) {
      try {
        this.$vs.loading();
        await this.$store.dispatch("calendar/importMarketLastYear", results);
        this.$vs.loading.close();
        this.$swal({
          icon: "success",
          title: "Data Uploaded"
        });
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    }
  }
};
</script>
