<template>
  <div class="md:w-2/5" @click="$refs.fileInput.click()">
    <div class="flex_container_community_excel">
      <div class="row">
        <div class="justify-center flex">
          <img
            src="@/Core/assets/images/pages/UploadExcel.png"
            class="excel_ipload_community"
          />
        </div>
        <span class="onboardcommunity_excel text-primary text-center mx-auto"
          >Upload Excel</span
        >
        <input
          type="file"
          ref="fileInput"
          class="hidden"
          accept=".xlsx, .xls"
          @change="handleClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import readxlfile from "read-excel-file";
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      excelData: {
        header: null,
        results: null,
        meta: null,
      },
    };
  },
  methods: {
    generateData({ header, results, meta }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: "Login Attempt",
          text: "Only support uploading one file!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: "Login Attempt",
          text: "Only supports upload .xlsx, .xls, .csv suffix files",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        return false;
      }
      this.uploadFile(rawFile);
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const fileName = rawFile.name;
          const workbook = XLSX.read(data, {
            type: "array",
            cellText: false,
            cellDates: true,
          });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet, {
            raw: false,
            dateNF: "yyyy-mm-dd",
          });
          const meta = { sheetName: firstSheetName, fileName: fileName };
          this.generateData({ header, results, meta });
          resolve();
          this.$vs.loading.close();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    handleClick(e) {
      // alert("Dfsdf")
      this.$vs.loading();
      const files = e.target.files;

      const rawFile = files[0];
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
    uploadFile(file) {
      this.$refs["fileInput"].value = null; // fix can't select the same excel
      this.readerData(file);
    },
  },
};
</script>

<style>
.excel_ipload_community {
  width: 38%;
}
.flex_container_community_excel {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f4f5f6;
  height: 200px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px dotted rgba(var(--vs-primary), 1) !important;
}
.onboardcommunity_excel {
  line-height: 30px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}
</style>
