<template>
  <div class="mr-1">
    <ImportExcelButton :onSuccess="loadDataInTable" />
  </div>
</template>

<script>
import ImportExcelButton from "@/Core/Views/CommonPlugins/import-excel/ImportExcelButton";

export default {
  components: {
    ImportExcelButton,
  },
  methods: {
    loadDataInTable({ results }) {
      results.forEach((element) => {
        if (element.isHoliday) {
          element.isHoliday =
            element.isHoliday.toLowerCase() === "yes" ? true : false;
        }
        if (element.isBankHoliday) {
          element.isBankHoliday =
            element.isBankHoliday.toLowerCase() === "yes" ? true : false;
        }
      });
      this.importData(results);
    },

    async importData(results) {
      try {
        this.$vs.loading();
        console.log(results, "ubygby");
        await this.$store.dispatch("calendar/importFX", results);

        this.$vs.loading.close();
        this.$swal({
          icon: "success",
          title: "Data Uploaded",
        });
      } catch (error) {
        if (error) this.$vs.loading.close();
      }
    },
  },
};
</script>
