<template>
  <div class="flex flex-col">
    <vx-card title="Money Market" class="md:w-2/5 mx-auto">
      <div class="mt-12 bg-white p-5 rounded-md">
        <ImportMoneyMarket />
      </div>
    </vx-card>
    <vx-card title="Market Last Year" class="md:w-2/5 mx-auto">
      <div class="mt-12 bg-white p-5 rounded-md">
        <div>
          <ImportMarketLastYear />
        </div>
      </div>
    </vx-card>
    <vx-card title="International Data" class="md:w-2/5 mx-auto">
      <div class="mt-12 bg-white p-5 rounded-md">
        <div>
          <ImportInternationalData />
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ImportMoneyMarket from "../components/ImportMoneyMarket";
import ImportMarketLastYear from "../components/ImportMarketLastYear";
import ImportInternationalData from "../components/ImportInternationalData";

export default {
  components: {
    ImportMoneyMarket,
    ImportMarketLastYear,
    ImportInternationalData
  }
};
</script>
