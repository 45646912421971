<template>
  <div class="flex mt-2"  v-if="
  isLogin == true &&
  (isManager == true || isOwner == true) &&
  isReader == false
">
    <vx-card title="FX Calendar" class="md:w-2/5 mx-auto">
      <div class="mt-12 bg-white p-5 rounded-md">
        <ImportFX />
      </div>
    </vx-card>
  </div>
  <div class="h-screen flex w-full bg-img" v-else>
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/Core/assets/images/pages/404.png"
        alt="graphic-404"
        class="mx-auto mb-4 max-w-full"
      />
      <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">
        404 - Page Not Found!
      </h1>
      <!-- <p class="sm:mx-0 mx-4 mb-4 sm:mb-16 d-theme-text-inverse">
        paraphonic unassessable foramination Caulopteris worral Spirophyton
        encrimson esparcet aggerate chondrule restate whistler shallopy
        biosystematy area bertram plotting unstarting quarterstaff.
      </p> -->
      <vs-button size="large" to="/">Back to Home</vs-button>
    </div>
  </div>
</template>

<script>
import ImportFX from "../components/ImportFX";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const profileRepository = RepositoryFactory.get("profile");
export default {
  data() {
    return {
      userDetails: {},
      oldUserID : {}
    }
  },
  components: {
    ImportFX
  },
  mounted() {
    this.getUserDetails()
  },
  methods: {
    async getUserDetails() {
      let newtoken = localStorage.getItem("token");
      this.token = jwt.decode(localStorage.getItem("token"));
      let token = jwt.decode(newtoken);
      this.oldUserID = structuredClone(token);
      console.log("toekn", this.oldUserID);
      ////debugger;

      let payload = {
          userData: {
            id: this.oldUserID.userID,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
      const { data } = await profileRepository.getAllUserData(payload);
        console.log("UserData", data[0]);
      if (data.length > 0) {
        this.$vs.loading.close();
        this.profileIsLoading = false;
        this.userProfileDetails = data;
        this.userDetails = data[0];
        this.userDetails.status = data[0].usersDetails_Status;
        this.userDetails.isManager =
          data[0].communityManager_UserId != null ? true : false;
        this.userDetails.isOwner =
          data[0].communityManager_Is_owner == 1 ? true : false;
        this.userDetails.isNormalUser =
          data[0].usersDetails_Password == null ? true : false;
        moment(data[0].usersDetails_DOB).format("DD-MM-YYYY");
      }
      // console.log(this.userDetails);
    },
  },
  computed: {
    isLogin() {
      if (localStorage.getItem("token")) {
        return true;
      } else {
        return false;
      }
    },
    isReader() {
      // console.log(this.userDetails);
      if (this.userDetails) {
        if (this.userDetails.isNormalUser == true) {
          return true;
        } else {
          return false;
        }
      }
    },
    isAdmin() {
      if (this.userDetails) {
        if (this.userDetails.status != 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isOwner() {
      if (this.userDetails) {
        if (this.userDetails.isOwner) {
          return true;
        } else {
          return false;
        }
      }
    },
    isManager() {
      if (this.userDetails) {
        if (this.userDetails.isManager) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

};
</script>
